import { of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { ApiService } from 'src/app/core/services/api/api.service';
import { catchError, flatMap } from 'rxjs/operators';
import { ContentPage } from 'src/app/core/models/cms/contentPage';

@Injectable({
  providedIn: 'root',
})
export class ContentPageResolver implements Resolve<ContentPage | null> {
  constructor(private api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const slug = route.params['slug'];
    if (!slug) {
      return of(null);
    }
    return this.api
      .getContentPageBySlug(slug)
      .pipe(catchError((_) => of(null)))
      .pipe(flatMap((p) => of(p)));
  }
}
