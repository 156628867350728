import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class SeoMetaService {
  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
   ) {}

  setMetaTags(
    metaEn: {
      title: string;
      description: string;
      keywords: string;
    },
    metaAr: {
      title: string;
      description: string;
      keywords: string;
    },
    shareImg?: string
  ): void {
    if (this.translateService.currentLang === 'ar') {
      this.addData(metaAr, shareImg);
    } else {
      this.addData(metaEn, shareImg);
    }
    this.translateService.onLangChange.subscribe(({ lang }) => {
      if (lang === 'en') {
        this.addData(metaEn, shareImg);
      } else if (lang === 'ar') {
        this.addData(metaAr, shareImg);
      }
    });
  }

  private addData(
    meta: {
      title: string;
      description: string;
      keywords: string;
    },
    shareImg?: string
  ): void {
    const title = `${meta.title} | MEDOC`;
    const defaultImg = environment.site_url + '/assets/images/medoc-logo.webp';
    const image = shareImg || defaultImg;

    this.title.setTitle(title);
    this.meta.updateTag({ name: 'title', content: title });
    this.meta.updateTag({ name: 'description', content: meta.description });
    this.meta.updateTag({ name: 'keywords', content: meta.keywords });
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });

    // meta tags
    this.meta.updateTag({ property: 'og:url', content: environment.site_url + this.router.url });
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:description', content: meta.description });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'MEDOC' });

    // twitter tags
    this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ property: 'twitter:title', content: title });
    this.meta.updateTag({ property: 'twitter:image', content: image });
    this.meta.updateTag({ property: 'twitter:description', content: meta.description });

    this.setCanonicalURL(environment.site_url + this.router.url);
 
  }

  setCanonicalURL(url?: string): void {
    const canonicalUrl = url ?? (isPlatformBrowser(this.platformId) ? window.location.href : '');
    if (isPlatformBrowser(this.platformId)) {
      const head = document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement | null = head.querySelector(`link[rel='canonical']`);
      if (!element) {
        element = document.createElement('link');
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', canonicalUrl);
    }  
  }
}
