import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  showModal$ = new Subject<boolean>();

  constructor() {}

  set showModal(s: boolean) {
    this.showModal$.next(s);
  }
}
