<form class="card m-auto" name="form" #f="ngForm" (submit)="f.valid && submitForm()" novalidate>
    <div class="card-body">
        <div class="profile-img">
            <img [src]="patient.profileImg || 'assets/images/default-avatar.webp'" alt="profile image" />
            <div class="image-input">
                <input type="file" id="profileImgInput" name="profileImg" [(ngModel)]="selectedProfileImg"
                    (change)="changeProfileImg($event)" #name="ngModel" />
                <label for="profileImgInput">
                    <app-icon icon="camera" size="12px"></app-icon>
                </label>
            </div>
        </div>
        <div class="form-group">
            <label for="name">{{ "form.name" | translate }}</label>
            <input type="text" name="name" id="name" class="form-control" [(ngModel)]="patient.name" #name="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
            <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                <div *ngIf="name.errors && name.errors['required']">
                    {{ "form.required" | translate }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="email">{{ "form.email" | translate }}</label>
            <input type="email" name="email" id="email" class="form-control" [(ngModel)]="patient.email"
                #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors && email.errors['required']">
                    {{ "form.required" | translate }}
                </div>
                <div *ngIf="email.errors && email.errors['email']">
                    {{ "form.email_not_valid" | translate }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="phone">{{ "form.phone" | translate }}</label>
            <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                [searchCountryFlag]="true" [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]" [selectedCountryISO]="CountryISO.Egypt" [phoneValidation]="true" name="phone" [(ngModel)]="phone"
                #phoneInput="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phoneInput.invalid }" required>
            </ngx-intl-tel-input>

            <div *ngIf="f.submitted && phoneInput.invalid" class="invalid-feedback">
                <div *ngIf="phoneInput.errors && phoneInput.errors['required']">
                    {{ "form.required" | translate }}
                </div>

                <div *ngIf="phoneInput.errors && phoneInput.errors['validatePhoneNumber']">
                    {{ "form.invalid_phone_number" | translate }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>{{ "global.gender" | translate }}</label>
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <input type="radio" name="patientGender" id="patientMale" [value]="1"
                        [(ngModel)]="patient.gender" />
                    <label for="patientMale" class="form-check-label mx-1">{{
                        "global.male" | translate
                        }}</label>
                </div>
                <div class="d-flex align-items-center mx-3">
                    <input type="radio" name="patientGender" id="patientFemale" [value]="2"
                        [(ngModel)]="patient.gender" />
                    <label for="patientFemale" class="form-check-label mx-1">{{
                        "global.female" | translate
                        }}</label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="dob">
                {{ "form.dob" | translate }}
            </label>
            <input type="date" name="dob" id="dob" class="form-control" [(ngModel)]="patient.dob" #dob="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && dob.invalid }" required />
            <div *ngIf="f.submitted && dob.invalid" class="invalid-feedback">
                <div *ngIf="dob.errors && dob.errors['required']">
                    {{ "form.required" | translate }}
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end align-items-center mt-4">
            <button type="button" class="btn btn-danger px-4 mr-2" *ngIf="hasDelete" (click)="onClickDelete()">
                {{'form.button.delete_account' | translate}}
            </button>
            <button type="submit" class="btn primary px-4">
                {{ "form.save" | translate }}
            </button>
        </div>
    </div>
</form>

<app-delete-confrim-modal #deleteConfirmModal (deleteConfirm)="onDelete($event)"> </app-delete-confrim-modal>