import { JsonObject, JsonProperty } from 'json2typescript';
import { City } from '../location';
 
export enum BookingChannelEnum {
  "Facebook" = 1,
  "Ads" = 2,
  "Referral" = 9,
  "Phone" = 3,
  "Friend" = 5,
  "Walk-in" = 7,
  "Other" = 6
}
  
@JsonObject('ProviderRequest')
export class ProviderRequest {
  @JsonProperty('contact_name', String)
  contactName = '';

  @JsonProperty('contact_email', String)
  contactEmail = '';

  @JsonProperty('contact_number', String)
  contactPhone = '';

  @JsonProperty("name", String)
  name: string = "";

  @JsonProperty("specialities", [Number], true)
  speciality: number[] | null = null;

  @JsonProperty("terms", Boolean , true)
  terms: boolean | null = null;

  @JsonProperty("country_code", String)
  countryCode: string = '+20';

  @JsonProperty("city_id", Number ,true)
  city: number | null = null;

  @JsonProperty("channel", Number)
  bookingChannel: number | null = null;

  country: string = '';

  selectCity: City | null = null;

  selectChannel :any= null;
 
  specialities : any | null = null;
  public static sorter = (a: ProviderRequest, b: ProviderRequest) =>
  a.name.localeCompare(b.name);
  
  // specia  this.filters.branches?.map((b) => b.id);
  // lity
  extractCountryCodeAndPhone(internationalNumber: string , countryCode: string):  string  {
    const phoneNumber = internationalNumber.replace(countryCode, '').split(' ').join(''); 
    return phoneNumber
  }
  
}


export const bookingChannelEnum = Object.keys(BookingChannelEnum).filter(v => !Number(v)).map(x => {
  return { id: BookingChannelEnum[x as keyof typeof BookingChannelEnum], val: x }
});