export enum DurationTypeEnum {
  Days = 1,
  Weeks = 2,
  Months = 3,
  Years = 4
}

export enum DurationTypeEnumAr {
  يوم = 1,
  أسبوع = 2,
  شهر = 3,
  عام = 4
}

export enum FrequencyTypeEnum {
  Once = 1,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Year
}

export enum FrequencyTypeEnumAr {
  "مرة واحدة" = 1,
  "دقيقة",
  "ساعة",
  "يوم",
  "اسبوع",
  "شهر",
  "سنة"
}
export enum UnitEnum {
  ml = 1,
  Tablets = 2,
  Capsules = 3,
  Ampoules = 4,
  Puffs = 5,
  Drops = 6,
  Vials = 7,
  SACHET = 8,
  Suppository = 9,
  "Eye Drops" = 10,
  Ointment = 11,
  CREAM = 12
}
export enum UnitEnumAr {
  مل = 1,
  قرص = 2,
  كبسولة = 3,
  أمبولة = 4,
  بخات = 5,
  قطرات = 6,
  أنبوبة = 7,
  كيس = 8,
  لبوسه = 9
}


export enum TimeEnum {
  'Once' = 0,
  '1 Hour' = 1,
  '2 Hours' = 2,
  '3 Hours' = 3,
  "6 Hours" = 6,
  "8 Hours" = 8,
  "12 Hours" = 12,
  "24 Hours" = 24
}


export enum MedNoteEnum {
  'Before Food' = 1,
  'After Food' = 2,
  'Fasting' = 3,
  'Before Sleep' = 4,
  'After Waking Up' = 5,
  'With food' = 6,
  'Every other day' = 7
}

export enum MedNoteEnumAr {
  'قبل الأكل' = 1,
  'بعد الأكل' = 2,
  'صايم' = 3,
  'قبل النوم' = 4,
  'بعد النوم' = 5,
  'مع الأكل' = 6,
  'كل يوم بعد يوم' = 7
}


export enum TypeEnum {
  medicines = 1,
  investigations = 2,
  complaints = 3,
  patientName = 4,
  diagnosis = 5,
  date = 6,
  doctorName = 7,
  age = 8
}
export enum Interface {
  Home = 0,
  Provider = 1,
}