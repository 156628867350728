<div class="bg-light full-min-height">
    <div class="d-flex justify-content-end px-5 pt-3">
        <button type="button" class="btn text-primary font-weight-bold " (click)="switchLanguage()">
            {{ "nav.language" | translate }}
        </button>
    </div>

    <!-- <a class="nav-item navbar-brand pt-3 d-flex pl-lg-0 mr-2" >
        <img src="/assets/images/medoc-logo.svg" class="mt-3" alt="MEDOC Logo" class="logo" />
      </a> -->
    <div class="container mb-3  py-md-0">
         <router-outlet></router-outlet>
         <div class="d-flex h-screen  flex-column  align-items-center justify-content-center">
            <div class="d-flex align-items-center">
               <small class="text-muted mr-1">{{'global.powered_by' | translate}} </small>
               <a href="{{wesiteUrl}}">
                 <img src="/assets/images/medoc-logo.svg" width="20" height="20" class="" alt="MEDOC Logo"  />
               </a>
            </div>
            <h2 class="text-center">{{"global.download_our_app" | translate}}</h2>
            <div class="d-flex align-items-center app-badges mt-3">
                <a href="https://play.google.com/store/apps/details?id=com.softworxsolutions.luxmedicard"
                    target="_blank">
                    <img src="/assets/images/apps/google-play-badge.svg" alt="" height="38px" />
                </a>
                <a href="https://apps.apple.com/eg/app/lux-medicard/id1559472458" target="_blank">
                    <img src="/assets/images/apps/app-store-badge-en.svg" alt="" height="40px" class="mx-2" />
                </a>
            </div>
            <p class="text-center app-description my-3">{{ "land.patient.records_desc" | translate }}</p>
        </div>
    </div>
</div>
