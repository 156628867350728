import { ToastService } from './../toast.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toastr: ToastService) {}

  handleError(errors: any) {
    const e = Object.assign(
      {},
      ...(function _flatten(o): any {
        if (o) {
          return [].concat(
            ...Object.keys(o).map((k) => {
              if (Array.isArray(o[k])) {
                return { [k]: `${k}: ${o[k].join(', ')}` };
              } else if (typeof o[k] === 'object') {
                return _flatten(o[k]);
              } else {
                return { [k]: `${k}: ${o[k]}` };
              }
            })
          );
        }
      })(errors.error)
    );

    this.showError(Object.values(e).join('\n'));
  }

  showError(msg: string) {
    this.toastr.error(msg);
  }
}
