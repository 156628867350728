import { ChildPatient, FileData, Patient, PatientImage, ReportFile } from 'src/app/core/models/patient';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../error/error.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiteCompany } from 'src/app/core/models/companies/company';
import { PrescriptionDetail } from 'src/app/core/models/Prescription';

@Injectable({
  providedIn: 'root',
})
export class PatientApiService extends ApiBaseService {
  baseUrl = environment.backend_host + '/api/patients';
 

  constructor(
    public http: HttpClient,
    public override errorService: ErrorService,
    protected override spinner: NgxSpinnerService
  ) {
    super(spinner, errorService);
  }

  getFamilyMembers = (): Observable<[ChildPatient]> => {
    return this.http
      .get<any>(`${this.baseUrl}/family-members`)
      .pipe(map(this.deserializeArrayAs(ChildPatient)));
  };

  getPatientCompanies = (patientId: number): Observable<[LiteCompany]> => {
    return this.http
      .get<any>(`${this.baseUrl}/${patientId}/lite-companies`)
      .pipe(map(this.deserializeArrayAs(LiteCompany)));
  };

  getPatientProfile(): Observable<Patient> {
    return this.http
      .get<Patient>(`${this.baseUrl}/profile`)
      .pipe(map(this.deserializeAs(Patient)));
  }

  addContactPatient(patient: Patient): Observable<Patient> {
    return this.http
      .post<Patient>(`${this.baseUrl}/contact`, this.serialize(patient))
      .pipe(map(this.deserializeAs(Patient)));
  }

  updateContactPatient(patient: Patient): Observable<Patient> {
    return this.http
      .put<Patient>(
        `${environment.backend_host}/api/users/settings`,
        this.serialize(patient)
      )
      .pipe(map(this.deserializeAs(Patient)));
  }

  deletePatient(patient: Patient): Observable<any> {
    return this.http
      .delete<Patient>(
        `${environment.backend_host}/api/users/settings`,
        this.serialize(patient)
      );
  }

  updateProfileImg(
    patientId: number,
    img: PatientImage
  ): Observable<PatientImage> {
    return this.http
      .put<Patient>(
        `${this.baseUrl}/${patientId}/profile-img`,
        this.serialize(img)
      )
      .pipe(map(this.deserializeAs(PatientImage)));
  }
  
  getPatientPrescription(uuid: string): Observable<PrescriptionDetail>{
    return this.http
      .get<any>(`${this.baseUrl}/prescriptions/${uuid}`)
      .pipe(map(this.deserializeAs(PrescriptionDetail)));
  };


  getPatientReports(uuid: string): Observable<ReportFile>{
    return this.http
      .get<any>(`${this.baseUrl}/reports/${uuid}`)
      .pipe(map(this.deserializeAs(ReportFile)));
  };

}
