import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  error(textOrTpl: string | TemplateRef<any>, options?: any) {
    options = options || {
      classname: 'bg-danger text-light',
      delay: 10000,
    };
    this.toasts.push({ textOrTpl, ...options });
  }

  sucess(textOrTpl: string | TemplateRef<any>, options?: any) {
    options = options || {
      classname: 'bg-success text-light',
      delay: 5000,
    };
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
