import { Speciality } from 'src/app/core/models/doctors/doctor';
import { PublicProviderSearchResult } from 'src/app/core/models/providers/providerSearchResults';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../error/error.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderSearchParams } from 'src/app/core/models/providers/providerSearchParams';
import { ProviderRequest } from 'src/app/core/models/providers/providerRequest';
import { ProviderSpeciality } from 'src/app/core/models/providers/providerSpeciality';
import { PublicProvider } from 'src/app/core/models/providers/provider';
import { SearchParams } from 'src/app/core/models/searchParams';
import { ProviderCompany } from 'src/app/core/models/companies/company';

@Injectable({
  providedIn: 'root',
})
export class ProviderApiService extends ApiBaseService {
  baseUrl = environment.backend_host + '/api/providers';

  constructor(
    public http: HttpClient,
    public override errorService: ErrorService,
    protected override spinner: NgxSpinnerService
  ) {
    super(spinner, errorService);
  }

  public getProviders(
    searchParams: ProviderSearchParams 
  ): Observable<PublicProviderSearchResult> {
    let p = new HttpParams();
    p = p.append('page', searchParams.page.toString());
    p = p.append('query', searchParams.query);
    searchParams.speciality.forEach(
      (id) => (p = p.append('speciality', id.toString()))
    );
    return this.http
      .get<any>(`${this.baseUrl}/`, { params: p })
      .pipe(map(this.deserializeAs(PublicProviderSearchResult)));
  }

  public getProviderBySlug = (slug: string): Observable<PublicProvider> =>
    this.http
      .get<any>(`${this.baseUrl}/${slug}/`)
      .pipe(map(this.deserializeAs(PublicProvider)));

  public getProviderBySubdomain = (subdomain: string): Observable<PublicProvider> =>
      this.http
      .get<any>(`${this.baseUrl}/web_subdomain/${subdomain}`)
      .pipe(map(this.deserializeAs(PublicProvider)));
  
  public createProviderRequest = (
    provider: ProviderRequest
  ): Observable<ProviderRequest> =>
    this.http
      .post<any>(`${this.baseUrl}/create`, this.serialize(provider))
      .pipe(map(this.deserializeAs(ProviderRequest)));

  // Specialities
  public getProviderSpecialties(
    providerId: number
  ): Observable<ProviderSpeciality[]> {
    return this.http
      .get<any>(`${this.baseUrl}/${providerId}/services`)
      .pipe(map(this.deserializeArrayAs(ProviderSpeciality)));
  }

  public allSpecialties(): Observable<Speciality[]> {
    return this.http
      .get<any>(`${this.baseUrl}/specialities`)
      .pipe(map(this.deserializeArrayAs(Speciality)));
  }

  public getProviderHomePage(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/homepage`);
  }

  public getProviderCompanies = (
    providerId: number | null | undefined,
    searchParams: SearchParams
  ): Observable<[ProviderCompany]> => {
    const p = this.serializeParamsObj(searchParams, new HttpParams());
    return this.http
      .get<any>(`${this.baseUrl}/${providerId}/companies`, { params: p })
      .pipe(map(this.deserializeArrayAs(ProviderCompany)));
  };
}
