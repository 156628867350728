import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facebook-pixel',
  templateUrl: './facebook-pixel.component.html',
  styleUrls: ['./facebook-pixel.component.scss'],
})
export class FacebookPixelComponent  {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.loadScript();
  }

 
  loadScript() {
    if (environment.use_analytics && isPlatformBrowser(this.platformId)) {
      if (document.getElementById('fb-pixel')) {
        return;
      }

      let script = this.renderer.createElement('script') as HTMLScriptElement;
      script.id = 'fb-pixel'
      script.async = true;
      this.renderer.appendChild(this.el.nativeElement, script);

      let scriptBody = this.renderer.createText(`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '360850409266356'); 
          fbq('track', 'PageView');
        `);
      this.renderer.appendChild(script, scriptBody);

      let noscript = this.renderer.createElement('noscript') as HTMLScriptElement;
      noscript.async = true;
      this.renderer.appendChild(this.el.nativeElement, noscript);
      scriptBody = this.renderer.createText(`
          <img height="1" width="1" 
          src="https://www.facebook.com/tr?id=360850409266356&ev=PageView
          &noscript=1"/>
        `);
      this.renderer.appendChild(noscript, scriptBody);
    }
  }
}
