import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ErrorService } from './../error/error.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  needAuth: string[] = [
    'patients/family-members',
    'lite-companies',
    'auth/check-patient',
    'bookings/',
    'patients/profile',
    'users/settings',
    'patients/contact',
  ];

  constructor(
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private afAuth: AngularFireAuth
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | any {
    this.spinner.show();

    if (this.needAuth.find((l) => l === request.url.split('api/')[1])) {
      if (this.auth.isAuthenticated()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Token ${this.auth.token}`,
          },
        });
        return this.handleNext(request, next);
      } else {
        this.afAuth.idToken.subscribe(
          (token) => {
            request = request.clone({
              setHeaders: {
                Authorization: `Token ${token}`,
              },
            });
            return this.handleNext(request, next);
          },
          (error) => {
            this.errorService.showError(error.message);
            return of(null);
          }
        );
      }
    } else {
      return this.handleNext(request, next);
    }
  }

  handleNext(request: any, next: any) {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          this.handleError(err);
        },
        () => {
          this.spinner.hide();
        }
      )
    );
  }

  handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      this.spinner.hide();
      if (err.status === 500) {
        const error =
          'Internal Error. Please report your issue to MEDOC Tech Support Team';

        this.errorService.showError(error);
      } else {
        this.errorService.handleError(err);
      }
    }
  }
}
