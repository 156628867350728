<header [style.background-image]="'url(' + page.image + ')'" *ngIf="page">
  <div class="container">
    <div class="row align-items-center h-100 align-items-center">
      <div class="col-12 col-lg-8 order-lg-1">
        <h1>{{ isEnglish ? page.header : page.headerAr }}</h1>
        <p class="my-3">{{ isEnglish ? page.subHeader : page.subHeaderAr }}</p>
      </div>
    </div>
  </div>
</header>

<div class="container py-5" *ngIf="page">
  <div class="row">
    <div
      class="col-12"
      [innerHTML]="(isEnglish ? page.content : page.contentAr) | safeHtml"
    ></div>
  </div>
</div>

<app-not-found *ngIf="is404"></app-not-found>
