import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Testimonial')
export class Testimonial {
  @JsonProperty('id', Number)
  id: number | undefined = undefined;

  @JsonProperty('name', String)
  name = '';

  @JsonProperty('title', String)
  title = '';

  @JsonProperty('testimonial', String)
  testimonial = '';

  @JsonProperty('image', String)
  image: string | null = null;

  @JsonProperty('rating', Number)
  rating: number | null = null;

  public static sorter = (a: Testimonial, b: Testimonial) =>
    a.name.localeCompare(b.name);
}

export enum ServicePeriodEnum {
  'monthly' = 1,
  'annually' = 12,
}

@JsonObject("SubscriptionBase")
export class SubscriptionBase {
    @JsonProperty("id", Number, true)
    id: number | null = null;

    @JsonProperty("price", Number, true)
    price: number | null = null;

    @JsonProperty("name", String, true)
    name: string | null = null;
  
    @JsonProperty("currency", String, true)
    currency: string | null = null;

    @JsonProperty("description", String, true)
    description: string | null = null;

    @JsonProperty("name_ar", String, true)
    nameAr: string | null = null;

    @JsonProperty("description_ar", String, true)
    descriptionAr: string | null = null;

    @JsonProperty("recurring_period", Number, true)
    recurringPeriod: ServicePeriodEnum | null = ServicePeriodEnum.annually;

    @JsonProperty("grace_period_days", Number, true)
    gracePeriodDays: number | null = null;

    @JsonProperty("icon", String, true)
    icon?: string | null = null;

    @JsonProperty("is_recommended", Boolean, true)
    isRecommended: boolean | null = null;
  
    monthlyPrice: number | null = null;
  
    
    getMonthlyPrice(): number | null {
      return this.recurringPeriod == 12 && this.price ? this.price / 12 : this.price;
    }
  
    getAnnualPrice(): number | null {
      return this.recurringPeriod == 1 && this.price ? this.price * 12 : this.price;
  }
  
  get isMonthlyPrice(): boolean {
     return this.recurringPeriod == 12
   }
}

@JsonObject("SubscriptionChild")
export class SubscriptionChild extends SubscriptionBase {
}

@JsonObject("MedocSubscription")
export class MedocSubscription extends SubscriptionBase {
    @JsonProperty("children", [SubscriptionChild], true)
   children: SubscriptionChild[] | null = null;
  
   
}