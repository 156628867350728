import { JsonObject, JsonProperty } from 'json2typescript';
import { ProviderCompany } from '../companies/company';
import { DoctorSpeciality } from '../doctors/doctor';

@JsonObject('MiniProvider')
export class MiniProvider {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('slug', String, true)
  slug: string | null = null;

  @JsonProperty('logo_img', String, true)
  logo: string | null = null;

  logoAlt: string | null = null;
  logoAltAr: string | null = null;

  init() {
    this.logoAlt = this.name + ' | ' + 'MEDOC';
    this.logoAltAr = this.nameAr + ' | ' + 'MEDOC';
  }
}

@JsonObject('ProviderInfo')
export class ProviderInfo extends MiniProvider {
  @JsonProperty('contact_number', String, true)
  contactNumber: string | null = null;

  @JsonProperty('discounts_str', String, true)
  discountsStr: string | null = null;

  @JsonProperty('discounts_str_ar', String, true)
  discountsStrAr: string | null = null;

  @JsonProperty('companies', [ProviderCompany], true)
  companies: ProviderCompany[] | null = null;
}

@JsonObject('PublicProvider')
export class PublicProvider extends MiniProvider {
  @JsonProperty('discounts_str', String, true)
  discountsStr: string | null = null;

  @JsonProperty('discounts_str_ar', String, true)
  discountsStrAr: string | null = null;

  @JsonProperty('discount_description', String, true)
  discountDesc: string | null = null;

  @JsonProperty('discount_description_ar', String, true)
  discountDescAr: string | null = null;

  @JsonProperty('branches_count', Number, true)
  branchesCount: number | null = null;

  @JsonProperty('bio', String, true)
  bio: string | null = null;

  @JsonProperty('bio_ar', String, true)
  bioAr: string | null = null;

  @JsonProperty('hotline', String, true)
  hotline: string | null = null;

  @JsonProperty('website_url', String, true)
  websiteUrl: string | null = null;

  @JsonProperty('instagram_url', String, true)
  instagramUrl: string | null = null;

  @JsonProperty('facebook_url', String, true)
  facebookUrl: string | null = null;

  @JsonProperty('is_featured', Boolean, true)
  isFeatured: boolean | null = null;
  @JsonProperty('reviews_count', Number, true)
  reviewsCount: number | null = null;
  
  @JsonProperty('avg_rating', Number, true)
  avgRating: number | null = null;

  @JsonProperty('specialities', [], true)
  specialities: DoctorSpeciality[]  = [];
 
}

export class TopSpecialtity {
  specialtiy: DoctorSpeciality | null = null;
  providers: number | null = null;
  doctors: number | null = null;
}
