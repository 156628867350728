import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.scss'],
})
export class GoogleAnalyticsComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loadGAScript();
      this.loadGTagScript();
      this.loadBookingEventScript();
      this.loadPhoneBookingeventScript();
      this.loadRequestDemoEventScript();
      this.loadGTagManager();
    }
  }

  loadGAScript() {
    if (document.getElementById('ga-script')) {
      return;
    }

    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'ga-script';
    script.src = `//www.googletagmanager.com/gtag/js?id=UA-171576442-1`;
    script.async = true;
    this.renderer.appendChild(this.el.nativeElement, script);

    let script2 = this.renderer.createElement('script') as HTMLScriptElement;
    let scriptBody = this.renderer.createText(`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());

          gtag('config', 'UA-171576442-1');
        `);
    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(this.el.nativeElement, script2);
  }

  loadGTagManager() {
    
    if (document.getElementById('gtag-manager')) {
      return;
    }

    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'gtag-manager';

    let scriptBody = this.renderer.createText(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-M554XTP');
    `);
    this.renderer.appendChild(script, scriptBody);
    // this.renderer.appendChild(this.el.nativeElement, script2);
    document.getElementsByTagName('head')[0].appendChild(script); 
  }

  loadGTagScript() {
    if (document.getElementById('gtag-script')) {
      return;
    }

    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'gtag-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=AW-527243633`;
    script.async = true;
    this.renderer.appendChild(this.el.nativeElement, script);

    let script2 = this.renderer.createElement('script') as HTMLScriptElement;
    let scriptBody = this.renderer.createText(`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-527243633');
        `);
    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(this.el.nativeElement, script2);
  }

  loadBookingEventScript() {
    if (document.getElementById('booking-event')) {
      return;
    }

    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'booking-event';
    script.async = true;
    
    let scriptBody = this.renderer.createText(`
      window.addEventListener('load', function () {
        document.querySelectorAll('.book-event-analytics').forEach(function (a) {
          a.onclick = function () {
            gtag('event', 'conversion', {
              'send_to': 'AW-527243633/VtZ_CP_jooAYEPGytPsB'
            });
          }
        })
      });
      `);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  loadPhoneBookingeventScript() {
    if (document.getElementById('phone-booking-event')) {
      return;
    }
    
    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'phone-booking-event';
    script.async = true;
    
    let scriptBody = this.renderer.createText(`
      window.addEventListener('load', function () {
        document.querySelectorAll('.phone-booking')[i].onclick = function () {
          a.onclick = function () {
            gtag('event', 'conversion', { 'send_to': 'AW-527243633/-qdBCMvSwIIYEPGytPsB' });
          }
        }
      });
      `);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  loadRequestDemoEventScript() {
    if (document.getElementById('request-demo-event')) {
      return;
    }

    let script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = 'request-demo-event';
    script.async = true;
    
    let scriptBody = this.renderer.createText(`
      window.addEventListener('load', function () {
        document.querySelectorAll('.demo-form')[i].onsubmit = function () {
          a.onclick = function () {
            gtag('event', 'conversion', {'send_to': 'AW-527243633/QUgJCMnTwIIYEPGytPsB'});
          }
        }
      });
      `);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  ngOnInit() { }
}
