import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon: string | null = null;
  @Input() size: string | null = null;
  @Input() width: string | null = null;
  @Input() height: string | null = null;
  @Input() iconPosition = 'left';

  public file = '';

  constructor() {}

  get iconPositionRight(): boolean {
    return this.iconPosition === 'right';
  }

  ngOnInit() {
    this.width = this.width || this.size;
    this.height = this.height || this.size;
    this.file = 'assets/icons/' + this.size + '.svg#' + this.icon;
  }
}
