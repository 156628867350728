import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extrnal-link-layout',
  templateUrl: './extrnal-link-layout.component.html',
  styleUrls: ['./extrnal-link-layout.component.scss']
})
export class ExtrnalLinkLayoutComponent implements OnInit {

  isBrowser = false;
  currentLang = 'en';
  wesiteUrl = environment.site_url;

  constructor(@Inject(PLATFORM_ID) platformId: any,
    private localizeService: LocalizeRouterService,
    private translateService: TranslateService) {
    this.isBrowser = isPlatformBrowser(platformId);
    const currentLang = this.translateService.currentLang;
      this.setLanguage(currentLang);
      this.setLangStyle(currentLang);
    }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.setLangStyle(this.currentLang);
    this.translateService.onLangChange.subscribe((r) => {
      this.currentLang = r.lang;
      this.setLangStyle(r.lang);
    });
   }

  setLangStyle(lang: string): void {
    if (this.isBrowser) {
      (document.querySelector('html') as HTMLElement).setAttribute('lang', lang);
      (document.querySelector('html') as HTMLElement).setAttribute(
        'dir',
        lang === 'ar' ? 'rtl' : 'ltr'
      );
      if (lang === 'ar') {
        document.body.classList.add('rtl');
      } else if (document.body.classList.contains('rtl')) {
        document.body.classList.remove('rtl');
      }
    }
  }

  setLanguage(lang: string): void {
    if (this.isBrowser) {
      this.localizeService.changeLanguage(lang);
      this.translateService.use(lang);
    }
  }

  switchLanguage(): void {
    this.currentLang = this.currentLang === 'en' ? 'ar' : 'en';
    this.setLanguage(this.currentLang);
  }

}
