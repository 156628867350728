import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RoutingService } from '../services/routing.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})
export class ProviderGuard implements CanActivate {
  constructor(
    private subdomainService: RoutingService,
    private router: Router,
    private localize: LocalizeRouterService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.subdomainService.isProvide) {
      return true;
    }
    
    const targetUrl = this.localize.translateRoute('/');
     
    if (state.url !== targetUrl){
      this.router.navigate([targetUrl]);
      return false;   
    }

    return true;  
  }
}
