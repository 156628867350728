import { ContentPage } from 'src/app/core/models/cms/contentPage';
import { MiniProvider } from 'src/app/core/models/providers/provider';
import { DoctorList, Speciality } from './../doctors/doctor';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Testimonial } from './testimonial';
import { Location } from '../location';

@JsonObject('HomepageContent')
export class HomepageContent {
  @JsonProperty('trusted_providers', [MiniProvider], true)
  featuredProivder: MiniProvider[] = [];

  @JsonProperty('testimonials', [Testimonial], true)
  testimonials: Testimonial[] = [];

  @JsonProperty('providers_count', Number, true)
  providersCount = 0;

  @JsonProperty('branches_count', Number, true)
  locationsCount = 0;

  @JsonProperty('patients_count', Number, true)
  patientsCount = 0;

  @JsonProperty('doctors_count', Number, true)
  doctorsCount = 0;

  @JsonProperty('cities', [Location], true)
  cities: Location[] = [];

  @JsonProperty('doctors', [DoctorList], true)
  doctors: DoctorList[] = [];

  @JsonProperty('blogs', [ContentPage], true)
  blogs: ContentPage[] = [];

  @JsonProperty('specialities', [Speciality], true)
  specialities: Speciality[] = [];
}
