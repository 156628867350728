import { PatientService } from 'src/app/core/services/patient.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { BookingApiService } from 'src/app/core/services/api/booking-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientApiService } from 'src/app/core/services/api/patient-api.service';

@Component({
  selector: 'app-authentication-modal',
  templateUrl: './authentication-modal.component.html',
  styleUrls: ['./authentication-modal.component.scss'],
})
export class AuthenticationModalComponent implements OnInit {
  @ViewChild('authModal') authModal: any;
  modalRef: NgbModalRef | null = null;
  isBrowser;
  windowRef: any;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) platformId: any,
    private authService: AuthenticationService,
    private bookingApi: BookingApiService,
    private patientApi: PatientApiService,
    private patientService: PatientService,
    private spinner: NgxSpinnerService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.authService.showModal$.subscribe((r) => {
        if (r) {
          this.openModal();
        } else {
          this.closeModal();
        }
      });
    }
  }

  ngOnInit(): void { }

  openModal() {
    this.modalRef = this.modalService.open(this.authModal);

  }

  closeModal() {
    this.modalRef?.dismiss();
  }


  onLogin() {
    this.closeModal();
    this.bookingApi.checkPatient().subscribe((r) => {
      this.spinner.hide();
      if (r.patientExists) {
        this.spinner.show();
        this.patientApi.getPatientProfile().subscribe(
          (patient) => {
            this.authService.patient = patient;
            this.spinner.hide();
          },
          (error) => {
            this.authService.signOut();
          }
        );
      } else {
        this.patientService.showModal = true;
      }
    });
  }

}
