import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Patient, PatientImage } from 'src/app/core/models/patient';
import { PatientApiService } from 'src/app/core/services/api/patient-api.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DeleteConfrimModalComponent } from '../delete-confrim-modal/delete-confrim-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-patient-contact',
  templateUrl: './form-patient-contact.component.html',
  styleUrls: ['./form-patient-contact.component.scss']
})
export class FormPatientContactComponent implements OnInit {
  @Input('hasDelete') hasDelete: boolean = false;
  patient: Patient = new Patient();
  selectedProfileImg: string | null = null;
  phone: any | null = null;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  @ViewChild("deleteConfirmModal")
  deleteConfirmModal: DeleteConfrimModalComponent | null = null;

  constructor(
    private patientService: PatientService,
    private auth: AuthenticationService,
    private toastr: ToastService,
    private translate: TranslateService,
    private patientApi: PatientApiService,
    private router: Router,
    private tarnslate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.initUserData();
    this.phone = (this.patient.countryCode || '') + this.patient.phone;
  }

  initUserData() {
    if (this.auth.patient) {
      this.patient = this.auth.patient;
    } else if (this.auth.currentUser) {
      this.patient.name = this.auth.currentUser.displayName || '';
      this.patient.email = this.auth.currentUser.email || '';
      this.patient.phone = this.auth.currentUser.phoneNumber || '';
    }
    this.patient = Object.assign(new Patient(), this.patient);
  }

  submitForm() {
    this.patient.countryCode = this.phone.dialCode;
    this.patient.phone = this.phone.e164Number.replace(this.phone.dialCode, '');
    if (this.auth.patient) {
      this.patientApi.updateContactPatient(this.patient).subscribe((r) => {
        this.auth.patient = this.patient;
        this.patientService.showModal = false;
        this.toastr.sucess(this.translate.instant('toastr.patient_updated'));
      });
    } else {
      this.patientApi.addContactPatient(this.patient).subscribe((r) => {
        this.auth.patient = r;
        this.patientService.showModal = false;
        this.toastr.sucess(this.translate.instant('toastr.patient_added'));
      });
    }
  }

  changeProfileImg(event: any) {
    this.getBase64(event.target.files[0]).then((r) => {
      if (r) {
        const patientImg = new PatientImage();
        patientImg.profileImg = r.replace(
          /^data:image\/(png|jpg|jpeg|webp);base64,/,
          ''
        );

        if (this.patient.id) {
          this.patientApi
            .updateProfileImg(this.patient.id, patientImg)
            .subscribe((r) => {
              this.patient.profileImg = r.profileImg;
              this.auth.patient = this.patient;
              this.toastr.sucess(
                this.translate.instant('toastr.profile_img_updated')
              );
            });
        }
      }
    });
  }

  getBase64 = (file: File): Promise<string | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || null);
      reader.onerror = (error) => reject(error);
    });


  onClickDelete() {
    this.deleteConfirmModal?.open(this.patientApi.deletePatient(this.patient));
  }

  onDelete(patient: Patient) {
    this.router.navigate(["/"]).then(() => {
      this.auth.signOut();
      this.toastr.sucess(
        this.tarnslate.instant("toastr.account_deleted"),
      );
    })
  }
}

