import { JsonObject, JsonProperty } from "json2typescript";
import { DurationTypeEnum, FrequencyTypeEnum, MedNoteEnum, TimeEnum, TypeEnum, UnitEnum } from "./medEnum";
import { DoctorList } from "./doctors/doctor";
import { TitleCasePipe } from "@angular/common";
import { Patient } from "./patient";


@JsonObject('Medicine')
export class Medicine {
    @JsonProperty('id', Number, true)
    id: number | null = null;

    @JsonProperty('name', String)
    name: string | null = null;

    @JsonProperty('duration', Number, true)
    duration = 1;

    @JsonProperty('duration_type', Number, true)
    durationType: DurationTypeEnum | null = null;

    @JsonProperty('dosage', Number, true)
    dosage = 1;

    @JsonProperty('dosage_type', Number, true)
    dosageType: UnitEnum | null = null;

    @JsonProperty('frequency', Number, true)
    frequency: TimeEnum | null = null;

    @JsonProperty('med_note', Number, true)
    medNote: MedNoteEnum | null = null;

    @JsonProperty('created_by', Number, true)
    createdBy: number | null = null;

    get getName() {
        return new TitleCasePipe().transform(this.name);
    }

}

@JsonObject('MedicineTemplate')
export class MedicineTemplate extends Medicine {
    @JsonProperty("is_featured", Boolean, true)
    isFeatured: boolean | null = null;
}

@JsonObject('PrescriptionMedicineBase')
export class PrescriptionMedicineBase {
    @JsonProperty('id', Number, true)
    id: number | null = null;

    @JsonProperty('name', String, true)
    name: string | null = null;

    @JsonProperty('duration', Number, true)
    duration: number | null = 1;

    @JsonProperty('duration_type', Number, true)
    durationType: DurationTypeEnum | null = null;

    @JsonProperty('frequency', Number, true)
    frequency: TimeEnum | null = null;

    @JsonProperty('dosage', Number, true)
    dosage: number | null = 1;

    @JsonProperty('dosage_type', Number, true)
    dosageType: UnitEnum | null = null;

    @JsonProperty('med_note', Number, true)
    medNote: MedNoteEnum | null = null;
}
@JsonObject('PrescriptionMedicine')
export class PrescriptionMedicine extends PrescriptionMedicineBase {

    constructor(medicine?: MedicineTemplate) {
        super();
        this.uiId = Math.floor(Math.random() * 1000);
        if (medicine) {
            this.dosage = medicine.dosage;
            this.dosageType = medicine.dosageType;
            this.frequency = medicine.frequency;
            this.duration = medicine.duration;
            this.durationType = medicine.durationType;
            this.medicine = medicine;
        }
    }
    // read only
    @JsonProperty('medicine', MedicineTemplate, true)
    medicine: MedicineTemplate | null = null;

    @JsonProperty("created_date", String, true)
    createdDate = null;

    @JsonProperty("updated_date", String, true)
    updatedDate = null;

    @JsonProperty("doctor", DoctorList, true)
    doctor: DoctorList | null = null;

    @JsonProperty('frequency_type', Number, true)
    frequencyType: FrequencyTypeEnum = FrequencyTypeEnum.Day;

    @JsonProperty('med_str', String, true)
    medStr: string | null = null;

    @JsonProperty('med_str_ar', String, true)
    medStrAr: string | null = null;

    // UI
    uiId = 1;
}

@JsonObject('PrescriptionSection')
export class PrescriptionSection {

    constructor(
        type?: TypeEnum,
        leftPx?: number,
        topPx?: number,
        widthPx?: number,
        height?: number
    ) {
        const prescriptionWidth = 800;
        const prescriptionHeight = 1200;

        this.type = type;
        this.leftPx = leftPx !== undefined ? leftPx : 0;
        this.leftPercent = leftPx !== undefined ? leftPx / prescriptionWidth * 100 : 0;
        this.topPx = topPx !== undefined ? topPx : 0;
        this.topPercent = topPx !== undefined ? topPx / prescriptionHeight * 100 : 0;
        this.widthPx = widthPx !== undefined ? widthPx : 0;
        this.widthPercent = widthPx !== undefined ? widthPx / prescriptionWidth * 100 : 0;
        this.height = height !== undefined ? height : 0;
    }

    @JsonProperty('type', Number, true)
    type: TypeEnum | undefined = TypeEnum.age;

    @JsonProperty('left_px', Number, true)
    leftPx: number = 0;

    @JsonProperty('left_percent', Number, true)
    leftPercent: number = 0;

    @JsonProperty('top_px', Number, true)
    topPx: number = 0;

    @JsonProperty('top_percent', Number, true)
    topPercent: number = 0;

    @JsonProperty('width_px', Number, true)
    widthPx: number = 0;

    @JsonProperty('width_percent', Number, true)
    widthPercent: number = 0;

    @JsonProperty('height', Number, true)
    height: number = 0;

}

@JsonObject('PrescriptionTemplate')
export class PrescriptionTemplate {
    @JsonProperty('prescription_paper', String, true)
    prescriptionPaper: string | null = null;

    @JsonProperty('print_sections', [PrescriptionSection], true)
    printSections: PrescriptionSection[] = [];
}
@JsonObject('PrescriptionDetail')
export class PrescriptionDetail {
    constructor() {
        setTimeout(() => {
            this.calculateExpiryDate()
        }, 0)
    }
    @JsonProperty('id', Number, true)
    id: number | null = null;

    @JsonProperty('meds', [PrescriptionMedicine], true)
    meds: PrescriptionMedicine[] = [];

    @JsonProperty('patient', Patient, true)
    patient: Patient | null = null;

    @JsonProperty('checkin', Number, true)
    checkin: number | null = null;

    @JsonProperty("doctor", DoctorList, true)
    doctor: DoctorList | null = null;

    @JsonProperty('prescription_template', PrescriptionTemplate, true)
    prescriptionTemplate: PrescriptionTemplate | null = null;

    @JsonProperty('created_date', String, true)
    createdDate: string | null = null;

    @JsonProperty('updated_date', String, true)
    updatedDate: string | null = null;

    @JsonProperty('uuid', String, true)
    uuid: string | null = null;

    @JsonProperty('name', String, true)
    name: string | null = null;

    isExpirDate: boolean | null = null;

    calculateExpiryDate() {
        if (this.createdDate) {
            const currentDate = new Date();
            const createdDate = new Date(this.createdDate);
            const expiryTimeInMillis = createdDate.getTime() + (3 * 24 * 60 * 60 * 1000);
            const expirDate = new Date(expiryTimeInMillis);

            const expirDateSeconds = Math.floor(expirDate.getTime() / 1000);
            const currentSeconds = Math.floor(currentDate.getTime() / 1000);
            this.isExpirDate = currentSeconds > expirDateSeconds;

        }
    }

}


export class DynamicSection {
    leftPercent: number | null = null;
    topPercent: number | null = null;
    widthPercent: number | null = null;
    sections: PrescriptionSection[] = [];

    constructor(
        leftPercent: number,
        topPercent: number,
        widthPercent: number,
        sections: PrescriptionSection[]) {
        this.leftPercent = leftPercent;
        this.topPercent = topPercent;
        this.widthPercent = widthPercent;
        this.sections = sections;
    }
}
export class PrintSections {
    static: PrescriptionSection[] = [];
    dynamic: DynamicSection[] = [];
}