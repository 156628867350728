export const environment = {
  production: true,
  staging: false,
  use_analytics: true,
  backend_host: "https://lux-api-prod.herokuapp.com",
  site_url: 'https://medoc.care',
  firebase: {
    apiKey: 'AIzaSyDvBLV1WLaQRECwKBEDSjSPwf6fJhuOlzY',
    authDomain: 'luxmedicard.firebaseapp.com',
    databaseURL: 'https://luxmedicard-default-rtdb.firebaseio.com',
    projectId: 'luxmedicard',
    storageBucket: 'luxmedicard.appspot.com',
    messagingSenderId: '402756397729',
    appId: '1:402756397729:web:4222465586163bd5a91ee0',
    measurementId: 'G-FPFD03BGXW',
  },
};
