import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {Request} from 'express'
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProviderApiService } from 'src/app/core/services/api/provider-api.service';
import { environment } from 'src/environments/environment';
import { RoutingService } from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderResolver implements Resolve<any> {
  private subdomain = ''
  private slug = ''

  constructor(private providerApi: ProviderApiService,
    private subdomainService: RoutingService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (this.subdomainService.isProvide) {
      this.subdomain = this.subdomainService.subdomain
      if (!this.subdomain) {
        return of(null);
      }
    } else {
      this.slug = route.params['slug'];
      if (!this.slug) {
        return of(null);
      }
    }
   if(this.subdomainService.isProvide){
      return this.providerApi.getProviderBySubdomain(this.subdomain || this.slug).pipe(
      catchError(() => of(null)));
   } else {
      return this.providerApi.getProviderBySlug(this.slug).pipe(
      catchError(() => of(null)));
    }
  }
}
