import { CheckPatient } from 'src/app/core/models/patient';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../error/error.service';
import { ApiBaseService } from './api-base.service';
import { Booking } from 'src/app/core/models/bookings';
import { BookingReview } from 'src/app/core/models/review';
import { SearchParams } from 'src/app/core/models/searchParams';
import { MedocSubscription } from 'src/app/core/models/cms/testimonial';
@Injectable({
  providedIn: 'root',
})
export class BookingApiService extends ApiBaseService {
  baseUrl = environment.backend_host + '/api';

  constructor(
    public http: HttpClient,
    errorService: ErrorService,
    spinner: NgxSpinnerService
  ) {
    super(spinner, errorService);
  }

  createBooking(booking: Booking): Observable<Booking> {
    return this.http
      .post<Booking>(`${this.baseUrl}/bookings/`, this.serialize(booking))
      .pipe(map(this.deserializeAs(Booking)));
  }

  getBookingReview(uuid: string): Observable<BookingReview>{
    return this.http
      .get<any>(`${this.baseUrl}/bookings/${uuid}`)
      .pipe(map(this.deserializeAs(BookingReview)));
  };
  

  checkPatient(): Observable<CheckPatient> {
    return this.http
      .post<boolean>(`${this.baseUrl}/auth/check-patient`, {})
      .pipe(map(this.deserializeAs(CheckPatient)));
  }

  getSubscriptions(params: SearchParams): Observable<MedocSubscription[]> {
    const p = this.serializeParamsObj(params, new HttpParams());
    return this.http
    .get<any>(`${this.baseUrl}/services/subscriptions`,{
      params: p
    }).pipe(map(this.deserializeArrayAs(MedocSubscription)));
  }
}
