import { JsonObject, JsonProperty } from 'json2typescript';

export enum PackageMaxTypeEnum {
  AMOUNT = 1,
  VISITS = 2,
}

@JsonObject('CompanyPackage')
export class CompanyPackage {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('company_id', Number, true)
  companyId: number | null = null;

  @JsonProperty('company_name', String, true)
  companyName: string | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('cash', Number, true)
  cash: number | null = null;

  @JsonProperty('credit', Number, true)
  credit: number | null = null;

  @JsonProperty('maximum', Number, true)
  max: number | null = null;

  @JsonProperty('maximum_type', Number, true)
  maxType: PackageMaxTypeEnum = PackageMaxTypeEnum.AMOUNT;

  @JsonProperty('created_date', String, true)
  createdDate = null;

  @JsonProperty('updated_date', String, true)
  updatedDate = null;

  get maxTypeStr() {
    return PackageMaxTypeEnum[this.maxType];
  }
}
