import { Patient } from 'src/app/core/models/patient';
import { PatientService } from 'src/app/core/services/patient.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-patient-contact-modal',
  templateUrl: './patient-contact-modal.component.html',
  styleUrls: ['./patient-contact-modal.component.scss'],
})
export class PatientContactModalComponent implements OnInit {
  @ViewChild('patientModal') patientModal: any;
  modalRef: NgbModalRef | null = null;
  patient: Patient = new Patient();
  isBrowser;
  selectedProfileImg: string | null = null;

  phone: any | null = null;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) platformId: any,
    private patientService: PatientService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.patientService.showModal$.subscribe((r) => {
        if (r) {
          this.openModal();
        } else {
          this.closeModal();
        }
      });
    }
  }

  ngOnInit(): void { }

  openModal() {
    this.modalRef = this.modalService.open(this.patientModal);
  }

  closeModal() {
    this.modalRef?.close();
  }

}
