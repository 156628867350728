import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentPageComponent } from './content-page/content-page.component';
import { ContentPageResolver } from 'src/app/core/resolvers/content-page.resolver';

const routes: Routes = [
  {
    path: ':slug',
    resolve: { contentPage: ContentPageResolver },
    component: ContentPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentPagesRoutingModule {}
