import { Patient } from 'src/app/core/models/patient';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { User } from '@firebase/auth-types';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  firstName = '';
  isMobileMenuShown = false;
  currentLang = 'en';
  isBrowser = false;
  user: User | null = null;
  patient: Patient | null = null;
  isEnglish : boolean = true;
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private auth: AuthenticationService,
    private afAuth: AngularFireAuth,
    private localizeService: LocalizeRouterService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.onAuthStateChanged();
   }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.handleNavbarStyle();
      document.addEventListener('scroll', () => {
        this.handleNavbarStyle();
      });
      this.currentLang = this.translateService.currentLang;
      this.setLangStyle(this.currentLang);
      this.translateService.onLangChange.subscribe((r) => {
        this.currentLang = r.lang;
        this.isEnglish =  r.lang == 'en' 
        this.setLangStyle(r.lang);
      });
      this.hideMobileMenu();
    }
  }

  switchLanguage(): void {
    this.currentLang = this.currentLang === 'en' ? 'ar' : 'en';
    this.setLanguage(this.currentLang);
  }

  handleNavbarStyle() {
    if (this.isBrowser) {
      const currentScroll = window.scrollY;
      if (currentScroll > 40) {
        document.getElementById('navbar')?.classList.add('scrolled');
      } else {
        document.getElementById('navbar')?.classList.remove('scrolled');
      }
    }
  }

  login() {
    this.auth.showModal = true;
    this.toggleMobileMenu(false);
  }

  signOut() {
    this.auth.signOut();
  }

  onAuthStateChanged(): void {
    this.afAuth.onAuthStateChanged((user) => {
      if (this.isBrowser) {
        this.patient = this.auth.patient;
        this.user = user;
      }
    });
  }

  setLanguage(lang: string): void {
    if (this.isBrowser) {
      this.localizeService.changeLanguage(lang);
      this.translateService.use(lang);
    }
  }

  setLangStyle(lang: string): void {
    if (this.isBrowser) {
      (document.querySelector('html') as HTMLElement).setAttribute('lang', lang);
      (document.querySelector('html') as HTMLElement).setAttribute(
        'dir',
        lang === 'ar' ? 'rtl' : 'ltr'
      );
      
      
      const currentRoute = decodeURIComponent(this.router.url);
      let baseRoute = currentRoute.replace(/^\/(en|ar)\//, ''); 
      let newRoute:any = currentRoute;
      if (lang === 'ar') { 
        document.body.classList.add('rtl');
      } else if (document.body.classList.contains('rtl')) {
        document.body.classList.remove('rtl');
      }
 
      // if (lang === 'ar') {
      //   if (baseRoute === 'solution/clinic-management-system') {
      //     newRoute = this.localizeService.translateRoute('ar/solution/نظام-إدارة-العيادات');
      //   }
      // } else if (lang === 'en') {
      //   if (baseRoute === 'solution/نظام-إدارة-العيادات') {
      //     newRoute = this.localizeService.translateRoute('en/solution/clinic-management-system');
      //   }
      //  }
      //  if (decodeURIComponent(newRoute)) {
      //    this.router.navigate([decodeURIComponent(newRoute)]);
      // }
     }
  }

  toggleMobileMenu(show: boolean) {
    if (this.isBrowser) {
      this.isMobileMenuShown = show;
      if (show) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }

  hideMobileMenu() {
    if (this.isBrowser) {
      document.querySelectorAll('.close-item').forEach((i) => {
        i.addEventListener('click', () => {
          this.toggleMobileMenu(false);
        });
      });
    }
  }
}
