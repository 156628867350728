import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SearchResults')
export class SearchResults {
  @JsonProperty('count', Number)
  count = 0;

  @JsonProperty('next', String)
  next: string | null = null;

  @JsonProperty('previous', String)
  previous: string | null = null;
}
