import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SearchParams')
export class SearchParams {
  @JsonProperty('page', Number)
  page = 1;

  @JsonProperty('search', String, true)
  search: string | null = null;

  @JsonProperty('date_from', String, true)
  dateFrom: string | null = null;

  @JsonProperty('date_to', String, true)
  dateTo: string | null = null;

  @JsonProperty('region', Number, true)
  region: number | null = null;

  @JsonProperty('country', Number, true)
  country: number | null = null;

  @JsonProperty('recurring_period', Number, true)
  recurringPeriod: number | null = null;

}
