import { PatientCheckInService} from 'src/app/core/models/providers/service';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DoctorList } from './doctors/doctor';
import { BookingSlotType, DoctorSlot } from './doctors/doctorSchedule';
import { Patient } from './patient';
import { SearchParams } from './searchParams';
import { SearchResults } from './searchResults';
import { publicBranch } from './providers/branch';
import { DoctorReview } from './review';

export enum BookingType {
  APP = 1,
  RECEPTIONIST = 2,
  DOCTOR = 3,
}

export enum CheckInType {
  SCAN = 1,
  MANUAL = 2,
}

export enum BookingState {
  BOOKED = 1,
  ARRIVED = 2,
  CHECKIN = 3,
  COMPLETE = 4,
  CANCELED = 5,
}

export enum BookingStatus {
  PENDING = 1,
  RESCHEDULED = 2,
  ACCEPTED = 3,
  REJECTED = 4,
}

export enum PaymentStatus {
  PENDING = 1,
  PARTIALLY_PAID = 2,
  PAID = 3,
  REFUND = 4,
}

export enum BookingFilterEnum {
  All = 1,
  Today = 2,
  Upcoming = 3,
  Past = 4,
  Pending = 5,
}

@JsonObject('Booking')
export class Booking {
  @JsonProperty('id', Number, true)
  id = undefined;

  @JsonProperty('branch_id', Number, true)
  branchId: number | null = null;

  @JsonProperty('branch', publicBranch, true)
  branch : publicBranch | null = null;

  @JsonProperty('patient_id', Number, true)
  patientId: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('slot_id', Number, true)
  slotId: number | null = null;

  @JsonProperty('patient', Patient, true)
  patient: Patient | null = null;

  @JsonProperty('doctor', DoctorList, true)
  doctor: DoctorList | null = null;

  @JsonProperty('slot', DoctorSlot, true)
  slot: DoctorSlot | null = null;

  @JsonProperty('booking_date', String, true)
  bookingDate: string | null = null;

  @JsonProperty('booking_time', String, true)
  bookingTime: string | null = null;

  @JsonProperty('followup_fees', Number, true)
  followupFees: number | null = null;

  @JsonProperty('fees', Number, true)
  fees: number | null = null;

  @JsonProperty('booking_type', Number, true)
  bookingType: BookingType | null = null;

  @JsonProperty('checkin_type', Number, true)
  checkinType: CheckInType | null = null;

  @JsonProperty('booking_slot_type', Number, true)
  bookingSlotType: BookingSlotType | null = null;

  @JsonProperty('state', Number, true)
  state: BookingState | null = null;

  @JsonProperty('payment_status', Number, true)
  paymentStatus: PaymentStatus | null = null;

  @JsonProperty('bookings_status', Number, true)
  bookingStatus: BookingStatus | null = null;

  @JsonProperty('is_referral', Boolean, true)
  isReferral = false;

  @JsonProperty('checkedin_by', String, true)
  checkedinBy = null;

  @JsonProperty('checkedout_by', String, true)
  checkedoutBy = null;

  @JsonProperty('accepted_by', String, true)
  acceptedBy = null;

  @JsonProperty('booked_by', String, true)
  bookedBy = null;

  @JsonProperty('patient_notes', String, true)
  patientNotes: string | null = null;

  @JsonProperty('insurance_company_id', Number, true)
  insuranceCompanyId: number | null = null;

  @JsonProperty('main_visit_id', String, true)
  mainVisitId: string | null = null;

  @JsonProperty('review', DoctorReview, true)
  review: DoctorReview | null = null

  @JsonProperty('services', [PatientCheckInService], true)
  services: PatientCheckInService[] | null = null;

  get datetime(): Date {
    return new Date(`${this.bookingDate} ${this.slot?.start}`);
  }

  get endTime() {
    return new Date(`${this.bookingDate} ${this.slot?.end}`);
  }

  isRangeSlot() {
    return this.bookingSlotType === BookingSlotType.range;
  }

  get isPaid() {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  get isPartiallyPaid() {
    return this.paymentStatus === PaymentStatus.PARTIALLY_PAID;
  }

  get isRefund() {
    return this.paymentStatus === PaymentStatus.REFUND;
  }

  get isPendingPayment() {
    return this.paymentStatus === PaymentStatus.PENDING;
  }

  get stateIsBooked() {
    return BookingState.BOOKED === this.state;
  }

  get stateIsArrived() {
    return BookingState.ARRIVED === this.state;
  }

  get stateIsCheckedIn() {
    return BookingState.CHECKIN === this.state;
  }

  get stateIsComplete() {
    return BookingState.COMPLETE === this.state;
  }

  get stateIsCanceled() {
    return BookingState.CANCELED === this.state;
  }

  get isConfirmed() {
    return BookingStatus.ACCEPTED === this.bookingStatus;
  }

  get isPending() {
    return BookingStatus.PENDING === this.bookingStatus;
  }

  get isReschedule() {
    return BookingStatus.RESCHEDULED === this.bookingStatus;
  }

  get isAppBooking() {
    return this.bookingType === BookingType.APP;
  }

  get isDoctorBooking() {
    return this.bookingType === BookingType.DOCTOR;
  }

  get isReceptionistBooking() {
    return this.bookingType === BookingType.RECEPTIONIST;
  }
}
@JsonObject('BookingSearchParams')
export class BookingSearchParams extends SearchParams {
  @JsonProperty('speciality', [Number], true)
  speciality = [];

  @JsonProperty('service', [Number], true)
  service = [];

  @JsonProperty('patient', [Number], true)
  patient = [];

  @JsonProperty('doctor', [Number], true)
  doctor = [];

  @JsonProperty('provider', [Number], true)
  provider = [];

  @JsonProperty('branch', [Number], true)
  branch = [];

  @JsonProperty('payment_status', [Number], true)
  paymentStatus = [];

  @JsonProperty('booking_type', [Number], true)
  bookingType = [];

  @JsonProperty('bookings_status', [Number], true)
  bookingStatus = [];

  @JsonProperty('state', [Number], true)
  state = [];

  @JsonProperty('booking_date_range_before', String, true)
  bookingDateBefore: string | null = null;

  @JsonProperty('booking_date_range_after', String, true)
  bookingDateAfter: string | null = null;

  @JsonProperty('is_upcoming', Boolean, true)
  isUpcoming = false;

  @JsonProperty('is_past', Boolean, true)
  isPast = false;

  @JsonProperty('is_pending', Boolean, true)
  isPending = false;

  @JsonProperty('is_today', Boolean, true)
  isToday = false;
}

@JsonObject('BookingSearchResult')
export class BookingSearchResult extends SearchResults {
  @JsonProperty('results', [Booking])
  results: Booking[] = [];
}

@JsonObject('PatientBookingNotification')
export class PatientBookingNotification {
  @JsonProperty('pending_count', Number, true)
  pendingCount = 0;

  @JsonProperty('booking', Booking, true)
  booking: Booking | null = null;
}
