import { JsonObject, JsonProperty } from 'json2typescript';
import { CompanyPackage } from './companyPackage';

@JsonObject('Company')
export class Company {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('slug', String, true)
  slug: string | null = null;

  @JsonProperty('contact_name', String, true)
  contactName: string | null = null;

  @JsonProperty('contact_number', String, true)
  contactNumber: string | null = null;

  @JsonProperty('contact_email', String, true)
  contactEmail: string | null = null;

  @JsonProperty('logo_img', String, true)
  logo: string | null = null;

  @JsonProperty('packages', [CompanyPackage], true)
  packages: CompanyPackage[] = [];

  @JsonProperty('created_date', String, true)
  createdDate: string | null = null;

  @JsonProperty('updated_date', String, true)
  updatedDate: string | null = null;

  packageId: number | null = null;
  isChecked: boolean | null = null;
}

@JsonObject('ProviderCompany')
export class ProviderCompany {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('company_id', Number, true)
  companyId: number | null = null;

  @JsonProperty('company', Company, true)
  company: Company | null = null;

  @JsonProperty('provider_id', Number, true)
  providerId: number | null = null;

  @JsonProperty('add_services', Boolean, true)
  addServices = true;

  @JsonProperty('is_active', Boolean, true)
  isActive = true;
}
@JsonObject('LiteCompany')
export class LiteCompany {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('company_id', Number, true)
  companyId: number | null = null;
}
