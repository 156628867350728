import { publicBranch } from './branch';
import { JsonObject, JsonProperty } from 'json2typescript';
import { SearchResults } from '../searchResults';
import { PublicProvider } from './provider';

@JsonObject('PublicProviderSearchResult')
export class PublicProviderSearchResult extends SearchResults {
  @JsonProperty('results', [PublicProvider])
  results: PublicProvider[] = [];
}

@JsonObject('BranchSearchResult')
export class BranchSearchResult extends SearchResults {
  @JsonProperty('results', [publicBranch])
  results: publicBranch[] = [];
}
