import { JsonObject, JsonProperty } from 'json2typescript';

export enum ContentPageStateEnum {
  Draft = 1,
  Unlisted = 2,
  Published = 3,
}

export enum ContentTypeEnum {
  Blog = 1,
  Page = 2,
}

export enum LanguageEnum {
  Arabic = 1,
  English = 2,
  Both = 3,
}

@JsonObject('ContentPage')
export class ContentPage {
  @JsonProperty('id', Number)
  id = undefined;

  @JsonProperty('header', String)
  header = '';

  @JsonProperty('subheader', String)
  subHeader = '';

  @JsonProperty('header_ar', String)
  headerAr = '';

  @JsonProperty('subheader_ar', String)
  subHeaderAr = '';

  @JsonProperty('content', String)
  content = '';

  @JsonProperty('content_ar', String)
  contentAr = '';

  @JsonProperty('slug', String)
  slug = '';

  @JsonProperty('image', String, true)
  image = '';

  @JsonProperty('state', Number, true)
  state: ContentPageStateEnum | null = null;

  @JsonProperty('contentType', Number, true)
  contentType: ContentTypeEnum = ContentTypeEnum.Page;

  @JsonProperty('language', Number, true)
  language: LanguageEnum = LanguageEnum.English;

  @JsonProperty('created_date', String)
  createdDate = '';

  @JsonProperty('updated_date', String)
  updatedDate = '';

  @JsonProperty('keywords', String, true)
  keywords: string = '';
  
  @JsonProperty('keywords_ar', String, true)
  keywordsAr: string = '';

  @JsonProperty('seo_description', String)
  seoDescription: string  = '';

  @JsonProperty('seo_description_ar', String)
  seoDescriptionAr: string | null = null;
 
 
  @JsonProperty('tags', [String])
  tags: string[] | null = null;

  @JsonProperty('tags_ar', [String])
  tagsAr: string[] | null = null;
  
  constructor() {}

  get contentTypeString(): string {
    return ContentTypeEnum[this.contentType] === 'Blog'
      ? 'Blog'
      : 'Content Page';
  }

  setLang(lang: LanguageEnum) {
    this.language = lang;
  }

  getLang() {
    return LanguageEnum[this.language];
  }

  imageAlt: string | null = null;
  imageAltAr: string | null = null;

  init() {
    this.imageAlt = this.header + ' | ' + this.subHeader + ' | ' + 'MEDOC';
    this.imageAltAr =
      this.headerAr + ' | ' + this.subHeaderAr + ' | ' + 'MEDOC';
  }
}
