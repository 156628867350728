import { JsonObject, JsonProperty } from 'json2typescript';
import { GMLocation } from '../gmLocation';
import { ContactNumber } from './contactNumber';
import { ProviderInfo } from './provider';

@JsonObject('publicBranch')
export class publicBranch {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('street_address', String, true)
  streetAddress: string | null = null;

  @JsonProperty('street_ar', String, true)
  streetAddressAr: string | null = null;

  @JsonProperty('area', String, true)
  area: string | null = null;

  @JsonProperty('area_ar', String, true)
  areaAr: string | null = null;

  @JsonProperty('city', String, true)
  city: string | null = null;

  @JsonProperty('city_ar', String, true)
  cityAr: string | null = null;

  @JsonProperty('organization', ProviderInfo, true)
  provider: ProviderInfo | null = null;

  @JsonProperty('location', GMLocation, true)
  location: GMLocation = new GMLocation();

  @JsonProperty('contact_numbers', [ContactNumber], true)
  contactNumbers: ContactNumber[] = [];

  get address() {
    return `${this.area}, ${this.city}`;
  }

  get addressAr() {
    return `${this.streetAddressAr} – ${this.cityAr}`;
  }
}
