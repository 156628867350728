import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";

@Component({
  selector: "app-delete-confrim-modal",
  templateUrl: "./delete-confrim-modal.component.html",
  styleUrls: ["./delete-confrim-modal.component.scss"],
})
export class DeleteConfrimModalComponent  {
  public obj: any;
  public deleteRequest: Observable<any> | null = null;
  @Output() deleteConfirm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("deleteConfirmModal") deleteConfirmModal: DeleteConfrimModalComponent | null = null;
  modalRef: NgbModalRef | null = null;
  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  open(request: Observable<any>, obj = null) {
    this.deleteRequest = request;
    this.obj = obj;
    this.modalRef = this.modalService.open(this.deleteConfirmModal);
  }

 
  close() {
    this.modalRef?.close();
  }

  delete() {
    this.spinner.show();
    this.deleteRequest?.subscribe(
      (r) => {
        this.spinner.hide();
        this.close();
        this.deleteConfirm.emit(this.obj);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
}
