import { JsonObject, JsonProperty } from 'json2typescript';
import { DoctorSpeciality } from '../doctors/doctor';
import { Service } from './service';

@JsonObject('ProviderSpeciality')
export class ProviderSpeciality {
  @JsonProperty('speciality', DoctorSpeciality)
  speciality: DoctorSpeciality | null = null;

  @JsonProperty('service', Service)
  service: Service | null = null;

  @JsonProperty('discount', Number)
  discount: number | null = null;

  get getSpeciality() {
    return this.speciality?.name;
  }
}
