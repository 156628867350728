import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { FormLoginComponent } from './form-login/form-login.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AuthenticationModalComponent } from './authentication-modal/authentication-modal.component';


@NgModule({
  declarations: [
    LoginComponent,
    FormLoginComponent,
    AuthenticationModalComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    TranslateModule,
    FormsModule,
    NgxIntlTelInputModule,
  ],
  exports: [
    FormLoginComponent,
    AuthenticationModalComponent
  ]
})
export class LoginModule { }
