import { JsonObject, JsonProperty } from 'json2typescript';
import { DoctorList, GenderEnum } from './doctors/doctor';
import { PDFSource } from 'ng2-pdf-viewer';
export enum FileTypeEnum {
  image,
  pdf,
  dcm,
  video,
  audio
}
@JsonObject('PatientImage')
export class PatientImage {
  @JsonProperty('profile_img', String, true)
  profileImg: string | null = null;
}

@JsonObject('ChildPatient')
export class ChildPatient {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('email', String)
  email: string | null = null;

  @JsonProperty('dob', String, true)
  dob: string | null = null;

  @JsonProperty('gender', Number, true)
  gender: GenderEnum  = GenderEnum.Female;

  @JsonProperty('lux_id', String, true)
  medocId: string | null = null;

  @JsonProperty('phone_number', String, true)
  phone: string | null = null;

  @JsonProperty('country_code', String, true)
  countryCode: string | null = null;

  @JsonProperty('parent', Number, true)
  parentId: number | null = null;

  @JsonProperty('profile_img', String, true)
  profileImg: string | null = null;

  age: number | null = null;

  setAge = () => {
    if (this.dob) {
      var ageDifMs = Date.now() - new Date(this.dob).getTime();
      var ageDate = new Date(ageDifMs);
      this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  };

  init() {
    this.setAge();
  }
  get getGender() {
    return GenderEnum[this.gender];
  }
}

@JsonObject('Patient')
export class Patient extends ChildPatient {
  @JsonProperty('user', Number, true)
  userId: number | null = null;

  @JsonProperty('lux_id', String, true)
  luxId: string | null = null;
  
  @JsonProperty('subs_expiration', String, true)
  expiration: string | null = null;

  @JsonProperty('social_id', String, true)
  socialId: string | null = null;

  @JsonProperty('social_id_img', String, true)
  socialIdImg: string | null = null;

  @JsonProperty('doctor', DoctorList, true)
  doctor: DoctorList | null = null;
}

@JsonObject('CheckPatient')
export class CheckPatient {
  @JsonProperty('patient_exists', Boolean)
  patientExists: boolean | null = null;
}

@JsonObject('FileData')
export class FileData {
    @JsonProperty('id', Number, true)
    id = undefined;

    @JsonProperty('file', String, true)
    file: string | null = null;

    @JsonProperty('filename', String, true)
    fileName: string  | null= null;

    @JsonProperty('file_type', String, true)
    fileExtension: string | null = null;

    @JsonProperty('file_size', Number, true)
    fileSize:  number | undefined = undefined;

    @JsonProperty('uploaded_by', String, true)
    uploadedBy: string | null = null;

    @JsonProperty('created_date', String, true)
    createdDate = '';

    @JsonProperty('updated_date', String, true)
    updatedDate = '';

  get isPdf() {
      if(!this.file){return}

      return this.file.includes('.pdf');
    }

    get fileType() {
      switch (this.fileExtension?.toLowerCase()) {
        case '.jpeg':
        case '.jpg':
        case '.png':
        case '.gif':
        case '.svg':
        case '.webp':
        case '.bmp':
            return FileTypeEnum.image;
        case '.pdf':
            return FileTypeEnum.pdf;
        case '.dcm':
            return FileTypeEnum.dcm;
        case '.m4v':
        case '.avi':
        case '.mpg':
        case '.mp4':
        case '.wmv':
        case '.mov':
            return FileTypeEnum.video;
        case '.mp3':
        case '.wav':
        case '.flac':
        case '.m4a':
        case '.aac':
        case '.aiff':
        case '.wma':
        case '.ogg':
        case '.pcm':
        case '.mka':
        case '.alac':
        case '.aiff':
        case '.au':
        case '.mid':
        case '.opus':
          return FileTypeEnum.audio;
        default:
            return null
    }
  }
  
  get name() {
        if(!this.fileName){return}
        const parts = this.fileName.split('/');
        return parts[parts.length - 1];
  }

  get srcFile(): string | Uint8Array | PDFSource  {
    if (!this.file) {return ''}  
    return this.file;
  }

}
@JsonObject('Report')
export class ReportFile {
    @JsonProperty('id', Number, true)
    id = undefined;

    @JsonProperty('doctor', DoctorList, true)
    doctor: DoctorList | null = null;
  
    @JsonProperty('files', [FileData], true)
    files: FileData[] = [];
    
    @JsonProperty('investigation_name', String, true)
    investigationName: string | null = null;

    @JsonProperty('date', String, true)
    date = null;

    @JsonProperty('created_date', String, true)
    createdDate = '';

    @JsonProperty('updated_date', String, true)
    updatedDate = '';

    @JsonProperty('uuid', String, true)
    uuid: string | null= null;
    
    isExpirDate: boolean | null = null;
}

