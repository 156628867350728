import { SearchParams } from 'src/app/core/models/searchParams';
import { SearchResults } from './searchResults';
import { JsonObject, JsonProperty } from 'json2typescript';

export enum LocationType {
  egypt = 1,
  city = 2,
  area = 3,
}

@JsonObject('Location')
export class Location {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('slug', String, true)
  slug: string | null = null;

  @JsonProperty('slug_ar', String, true)
  slugAr: string | null = null;

  @JsonProperty('location_type', Number, true)
  locationType: LocationType | null = null;

  @JsonProperty('parent', Location, true)
  parent: Location | null = null;
}

@JsonObject('Region')
export class Region extends Location {
}
@JsonObject('City')
export class City extends Location {
}

@JsonObject('Country')
export class Country extends Location {
    @JsonProperty('code', String, true)
    code: string | null = null;

    @JsonProperty('currency', String, true)
    currency: string | null = null;

    @JsonProperty('currency_symbol', String, true)
    currencySymbol: string | null = null;

    @JsonProperty('phone', String, true)
    phone: string | null = null;
}
@JsonObject('LocationSearchResults')
export class LocationSearchResults extends SearchResults {
  @JsonProperty('results', [Location], true)
  results: Location[] = [];
}
@JsonObject('RegionSearchResult')
export class RegionSearchResult extends SearchResults {
  @JsonProperty('results', [Region])
  results: Region[] = [];
}
@JsonObject('CitySearchResult')
export class CitySearchResult extends SearchResults {
    @JsonProperty('results', [City])
    results: City[] = [];
}
@JsonObject('CountrySearchResult')
export class CountrySearchResult extends SearchResults {
    @JsonProperty('results', [Country])
    results: Country[] = [];
}
@JsonObject('LocationSearchParams')
export class LocationSearchParams extends SearchParams {
  @JsonProperty('location_type', Number, true)
  locationType: LocationType = LocationType.egypt;

  // used when fetching areas
  @JsonProperty('parent', Number, true)
  parentId: number | null = null;
}
