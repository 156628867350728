<div class="content">
  <div class="header">
    <p>4</p>
    <img src="../../assets/images/favicon.svg" alt="" />
    <p>4</p>
  </div>

  <h1 class="description">{{ "global.not_found" | translate }}</h1>
  <p>
    {{ "global.not_found_details" | translate }}
  </p>
  <a class="button primray" [routerLink]="'/' | localize">{{
    "global.back_home" | translate
  }}</a>
</div>
