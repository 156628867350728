import { SeoMetaService } from 'src/app/core/services/seo-meta.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentPage } from 'src/app/core/models/cms/contentPage';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
})
export class ContentPageComponent implements OnInit {
  page: ContentPage | null = null;
  is404 = false;
  isEnglish = true;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private seo: SeoMetaService
  ) {
    this.setLanguage();
    this.loadData();
  }

  ngOnInit() {}

  loadData() {
    this.route.data.subscribe((resolversData) => {
      this.page = resolversData['contentPage'];
      if (this.page != null) {
        this.seo.setMetaTags(
          {
            title: this.page.header,
            description: this.page.subHeader,
            keywords: this.page.keywords || 'health, wellness, medical, clinic, hospital, polyclinic',
          },
          {
            title: this.page.headerAr,
            description: this.page.subHeaderAr,
            keywords: this.page.keywordsAr || 'الرعاية الصحية, الصحة, الطب, العيادة, المستشفى, العيادات متعددة التخصصات',
          }
        );
      } else {
        this.is404 = true;
        this.page = null;
      }
    });
  }

  setLanguage() {
    this.isEnglish = this.translateService.currentLang === 'en';
    this.translateService.onLangChange.subscribe((r) => {
      this.isEnglish = r.lang == 'en';
    });
  }
}
