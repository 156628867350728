import { JsonObject, JsonProperty } from 'json2typescript';
import { ContentPage } from './contentPage';

@JsonObject('ContentPageSearchResult')
export class ContentPageSearchResult {
  @JsonProperty('count', Number)
  count = 0;

  @JsonProperty('next', String)
  next: string | null = null;

  @JsonProperty('previous', String)
  previous: string | null = null;

  @JsonProperty('results', [ContentPage])
  results: ContentPage[] = [];

  @JsonProperty('tags', [String])
  tags: string[] = [];

  @JsonProperty('tags_ar', [String])
  tagsAr: string[] = [];
}
