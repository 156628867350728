import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BaseModel')
export class BaseModel {
  @JsonProperty('created_date', String, true)
  createdDate: string | null = null;

  @JsonProperty('updated_date', String, true)
  updatedDate: string | null = null;
}
