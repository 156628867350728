import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { WindowService } from 'src/app/core/window.service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit, OnDestroy {
  @Output('onLogin') onLogin: EventEmitter<void> = new EventEmitter();
  @ViewChild('authModal') authModal: any;
  phone: any | null = null;
  password = '';

  windowRef: any;

  verificationCode: string | null = null;
  appVerifier: RecaptchaVerifier | null = null;
  isRecaptchaVerified = false;

  user: any;
  isCodeFormShown = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  constructor(
    private authService: AuthenticationService,
    private win: WindowService,
    private toastr: ToastService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnDestroy(): void {
    this.phone = null;
    this.verificationCode = null;
    this.isCodeFormShown = false;
  }

  ngOnInit(): void {
    this.handleRecaptchaVerifier();
  }

  sendVerificationCode() {
    if (this.windowRef) {
      this.spinner.show();
      this.appVerifier = this.windowRef?.recaptchaVerifier;

      if (this.appVerifier) {
        this.authService
          .loginPhone(this.phone.internationalNumber, this.appVerifier)
          .then((res: any) => {
            this.windowRef.confirmationResult = res;
            this.isCodeFormShown = true;
            this.spinner.hide();
          })
          .catch((error) => {
            this.spinner.hide();
             switch (error.code) {
              case 'auth/invalid-phone-number':
                this.toastr.error('The phone number is invalid.');
                break;
              case 'auth/quota-exceeded':
                this.toastr.error('SMS quota exceeded. Try again later.');
                break;
              case 'auth/too-many-requests':
                this.toastr.error('Too many requests. Try again later.');
                break;
              default:
                this.toastr.error('An error occurred: ' + error.message);
            }
          });
      }
    }
  }

  verifyLoginCode() {
    this.spinner.show();
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then((result: any) => {
        this.afterLogin();
      })
      .catch((error: any) => {
        this.spinner.hide();
        this.toastr.error(error + ', Incorrect code entered?');
      });
  }

  signUpWithGoogle(): void {
    this.spinner.show();
    this.authService.signInWithGoogleAuthProvider().then(
      () => {
        this.afterLogin();
      },
      (error: FirebaseError) => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
    );
  }

  signUpWithFacebook(): void {
    this.spinner.show();
    this.authService.signInWithFacebookAuthProvider().then(
      () => {
        this.afterLogin();
      },
      (error: FirebaseError) => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
    );
  }

  afterLogin() {
    this.isCodeFormShown = false;
    this.spinner.hide();
    this.onLogin.emit();
  }

  editPhone() {
    this.handleRecaptchaVerifier();
    this.isCodeFormShown = false;
  }

  handleRecaptchaVerifier() {
    this.isRecaptchaVerified = false;
    setTimeout(() => {
      this.windowRef = this.win.windowRef;
      this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container-1',
        {
          size: 'normal',
          callback: (response: any) => {
            this.isRecaptchaVerified = true;
          },
          'expired-callback': () => {
            this.isRecaptchaVerified = false;
          },
        },
        getAuth()
      );
      this.windowRef.recaptchaVerifier?.render();
      this.windowRef.recaptchaVerifier?.verify().then((r: any) => {
        this.isRecaptchaVerified = true;
      });
    }, 100);
  }
 
  
}
