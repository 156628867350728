import { JsonObject, JsonProperty } from 'json2typescript';

export enum BookingSlotType {
  slot = 1,
  range = 2,
}

@JsonObject('DoctorDaySchedule')
export class DoctorDaySchedule {
  @JsonProperty('fully_booked', Boolean, true)
  fullyBooked: boolean | null = null;

  @JsonProperty('start', String, true)
  start: string | null = null;

  @JsonProperty('end', String, true)
  end: string | null = null;

  @JsonProperty('date', String, true)
  date: string | null = null;
}

@JsonObject('DoctorDayScheduleSearchParams')
export class DoctorDayScheduleSearchParams {
  @JsonProperty('branch_id', Number, true)
  branchId: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('start_date', String, true)
  startDate: string | null = new Date().toDateString();

  @JsonProperty('end_date', String, true)
  endDate: string | null = null;
}

@JsonObject('DoctorDaySlotsSearchParams')
export class DoctorDaySlotsSearchParams {
  @JsonProperty('branch_id', Number, true)
  branchId: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('date', String, true)
  startDate: string | null = null;
}

@JsonObject('DoctorDayScheduleSearchResults')
export class DoctorDayScheduleSearchResults {
  @JsonProperty('branch_type', Number, true)
  branchType: BookingSlotType | null = null;

  @JsonProperty('results', [DoctorDaySchedule], true)
  results: DoctorDaySchedule[] = [];
}

@JsonObject('SpotsLeft')
export class SpotsLeft {
  @JsonProperty('fully_booked', Boolean, true)
  fullyBooked: boolean | null = null;

  @JsonProperty('spots_left', Number, true)
  spotsLeft: number | null = null;
}

@JsonObject('DoctorSlot')
export class DoctorSlot {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('is_active', Boolean, true)
  isActive: boolean | null = null;

  @JsonProperty('start', String, true)
  start: string | null = null;

  @JsonProperty('end', String, true)
  end: string | null = null;

  @JsonProperty('day', Number, true)
  day: number | null = null;

  @JsonProperty('capacity', Number, true)
  capacity: number = 1;

  @JsonProperty('spots_left', SpotsLeft, true)
  spotsLeft: SpotsLeft | null = null;

  formatedStart: string | null = null;
  formatedEnd: string | null = null;
}

export const to12HrsFormat = (
  time: string | null,
  locale: string
): string | null => {
  if (time) {
    const h = time.match(/^(\d+)/);
    const m = time.match(/:(\d+)/);
    let amPm: 'AM' | 'PM' | 'ص' | 'م' = locale === 'en' ? 'AM' : 'ص';

    if (h && m) {
      let hours = Number(h[1]);
      let minutes = Number(m[1]);
      if (hours === 0) {
        hours = 12;
      } else if (hours === 12) {
        amPm = locale === 'en' ? 'PM' : 'م';
      } else if (hours > 12) {
        hours = hours - 12;
        amPm = locale === 'en' ? 'PM' : 'م';
      }
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = '0' + sHours;
      if (minutes < 10) sMinutes = '0' + sMinutes;
      return sHours + ':' + sMinutes + ' ' + amPm;
    }
  }

  return null;
};
