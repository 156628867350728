import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-hotjar',
  templateUrl: './hotjar.component.html',
  styleUrls: ['./hotjar.component.scss'],
})
export class HotjarComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.loadScript();
  }

  loadScript() {
    if (environment.use_analytics && isPlatformBrowser(this.platformId)) {
      if (document.getElementById('hotjar-script')) {
        return;
      }
      const scriptTag = this.renderer.createElement('script');
      scriptTag.id = 'hotjar-script';
      scriptTag.innerHTML = `
        <!-- Hotjar Tracking Code for https://medoc.care -->
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2975591,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
      this.renderer.appendChild(this.el.nativeElement, scriptTag);
    }
  }

  ngOnInit(): void {
  }
}
