import { DoctorScheduleComponent } from './../doctors/doctor-schedule/doctor-schedule.component';
import { DoctorCardComponent } from './../doctors/doctor-card/doctor-card.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HumanizeParamPipe } from './pipes/humanize-param.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BackgroundImagePipe } from './pipes/background-image.pipe';
import { FacebookPixelComponent } from './third-parties/facebook-pixel/facebook-pixel.component';
import { GoogleAnalyticsComponent } from './third-parties/google-analytics/google-analytics.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { IconComponent } from './icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { HotjarComponent } from './third-parties/hotjar/hotjar.component';
import { DoctorFilterCardComponent } from './doctor-filter-card/doctor-filter-card.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProvidersSectionComponent } from './providers-section/providers-section.component';
import { ToastComponent } from './toast/toast.component';
import { SwiperModule } from 'swiper/angular';
import { MedicalRecordsSectionComponent } from './medical-records-section/medical-records-section.component';
import { FormPatientContactComponent } from './form-patient-contact/form-patient-contact.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DeleteConfrimModalComponent } from './delete-confrim-modal/delete-confrim-modal.component';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { DoctorReviewsComponent } from './doctor-reviews/doctor-reviews.component';
import { SocialShareComponent } from './social-share/social-share.component';

@NgModule({
  declarations: [
    HumanizeParamPipe,
    SafePipe,
    SafeHtmlPipe,
    BackgroundImagePipe,
    GoogleAnalyticsComponent,
    FacebookPixelComponent,
    IconComponent,
    HotjarComponent,
    NotFoundComponent,
    DoctorFilterCardComponent,
    ProvidersSectionComponent,
    ToastComponent,
    DoctorCardComponent,
    DoctorScheduleComponent,
    MedicalRecordsSectionComponent,
    FormPatientContactComponent,
    DeleteConfrimModalComponent,
    NumberSuffixPipe,
    DoctorReviewsComponent,
    SocialShareComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    TranslateModule,
    LocalizeRouterModule,
    SelectDropDownModule,
    LazyLoadImageModule,
    SwiperModule,
    NgxIntlTelInputModule,    
  ],
  exports: [
    NgbModule,
    HumanizeParamPipe,
    SafePipe,
    SafeHtmlPipe,
    NumberSuffixPipe,
    BackgroundImagePipe,
    GoogleAnalyticsComponent,
    FacebookPixelComponent,
    IconComponent,
    HotjarComponent,
    NotFoundComponent,
    DoctorFilterCardComponent,
    LazyLoadImageModule,
    ProvidersSectionComponent,
    ToastComponent,
    DoctorCardComponent,
    DoctorScheduleComponent,
    MedicalRecordsSectionComponent,
    FormPatientContactComponent,
    DeleteConfrimModalComponent,
    SelectDropDownModule,
    DoctorReviewsComponent,
    SocialShareComponent
  ],
  providers: [DatePipe],
})
export class SharedModule { }
