import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('GMLocation')
export class GMLocation {
    @JsonProperty('latitude', Number, true)
    lat = 30.0444196;

    @JsonProperty('longitude', Number, true)
    long = 31.2357116;
}
