import { Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private isProviderPage : boolean |any = false;
  private hostName: string = ''; 
  private subdomainName : string = '';
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request) {
    if (isPlatformServer(platformId)) {
      const host = request?.hostname;
      this.hostName = host;
      this.isProviderPage = this.isProvider(host);
      this.subdomainName=  this.extractSubdomain(host)
    } else if (isPlatformBrowser(platformId)) {
      const host = window.location.host;
      this.isProviderPage = this.isProvider(host);
      this.subdomainName=  this.extractSubdomain(host)
    }
   }

  extractSubdomain(host: string): string {
     let subdomain = host.split('.')[0];
    if (environment.staging) {
      subdomain = subdomain.replace('staging-', '');
    }
    this.subdomainName = subdomain;
    return subdomain;
  }


  isProvider(host: string): Boolean {
    let subdomain = this.extractSubdomain(host);
    return this.isHomeLayout(subdomain) ? false : true;
  }
  
  get isProvide(): Boolean { 
    return this.isProviderPage
  }

  isHomeLayout(subdomain: string): boolean {
    return (
      subdomain === 'www' ||
      subdomain === 'medoc' ||
      subdomain === 'staging' ||
      subdomain === 'localhost' ||
      subdomain === 'localhost:4200' ||
      subdomain === 'localhost:4201' ||
      !subdomain
    );
  }

  get subdomain(): string {
    return this.subdomainName
  }
}
