
import { JsonObject, JsonProperty } from 'json2typescript';
import { ProviderCompany } from '../companies/company';
import { SearchResults } from '../searchResults';

@JsonObject('Service')
export class Service {
  @JsonProperty('id', Number)
  id: number | null = null;

  @JsonProperty('name', String)
  name: string | null = null;

  @JsonProperty('name_ar', String)
  nameAr: string | null = null;

 
}

@JsonObject('ReviewService')
export class ReviewService { 
  @JsonProperty('service_name', String)
  name: string | null = null;

  @JsonProperty('service_name_ar', String)
  nameAr: string | null = null;
}

@JsonObject('ProviderServiceBase')
export class ProviderServiceBase {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('provider_id', Number, true)
  providerId: number | null = null;

  @JsonProperty("service_id", Number, true)
  serviceId = undefined;

  @JsonProperty('speciality_id', Number, true)
  specialityId = undefined;

  @JsonProperty('discount', Number, true)
  discount = 0;

  @JsonProperty('show_price', Boolean, true)
  showPrice = true;

  @JsonProperty('service', Service, true)
  service: Service | null = null;

  // @JsonProperty("speciality", Speciality, true)
  // speciality: Speciality | null = null;

}

@JsonObject('ProviderServicePrice')
export class ProviderServicePrice {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('service', ProviderServiceBase, true)
  service: ProviderServiceBase | null = null;

  // @JsonProperty('branch', Branch, true)
  // branch: Branch | null = null;

  @JsonProperty('company_id', Number, true)
  providerCompanyId: number | null = null;

  @JsonProperty('company', ProviderCompany, true)
  providerCompany: ProviderCompany | null = null;

  @JsonProperty('price', Number, true)
  price: number | null = null;

  // UI
  isChecked: boolean | null = null;

  @JsonProperty('service_name', String, true)
  servivceName: string | null = null;

  @JsonProperty('service_name_ar', String, true)
  servivceNameAr: string | null = null;
}

@JsonObject('ProviderService')
export class ProviderService extends ProviderServiceBase {
  @JsonProperty('prices', [ProviderServicePrice], true)
  prices: ProviderServicePrice[] | null = null;

}

@JsonObject('ProviderServicePriceSearchResult')
export class ProviderServicePriceSearchResult extends SearchResults {
  @JsonProperty('results', [ProviderServicePrice], true)
  results: ProviderServicePrice[] = [];
}

@JsonObject('PatientCheckInService')
export class PatientCheckInService {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('service_price_id', Number, true)
  serviceId: number | null = null;

  @JsonProperty('service', ProviderServiceBase, true)
  service: ProviderServiceBase | null = null;

  @JsonProperty('service_price', ProviderServiceBase, true)
  servicePrice: ProviderServiceBase | null = null;
  
}
