<ng-template #deleteConfirmModal let-modal>
    <div class="modal-header border-bottom">
        <h5>{{'global.delete_confirm_msg' | translate}}</h5>
    </div>
    <div class="modal-footer pt-3">
        <div>
            <button class="btn mr-2" (click)="close()">
                {{'form.button.no_cancel' | translate}}
            </button>
            <button class="btn btn-danger" (click)="delete()">
                {{'form.button.yes_delete' | translate}}
            </button>
        </div>
    </div>
</ng-template>