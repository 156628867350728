import { JsonObject, JsonProperty } from 'json2typescript';

export enum PhoneTypeEnum {
  Hotline = 0,
  Landline = 1,
  Mobile = 2,
}

@JsonObject('ContactNumber')
export class ContactNumber {
  @JsonProperty('id', Number)
  id: number | undefined = undefined;

  @JsonProperty('phone_number', String)
  phoneNumber: string | undefined = undefined;

  @JsonProperty('phone_type', Number, true)
  phoneType: PhoneTypeEnum | null = null;
}
