import { BaseModel } from './baseModel';
import { JsonObject, JsonProperty } from 'json2typescript';
import { publicBranch } from './providers/branch';
import { DoctorList } from './doctors/doctor';
import { ReviewService } from './providers/service';

@JsonObject('DoctorReview')
export class DoctorReview extends BaseModel {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('checkin_id', Number, true)
  checkinId: number | null = null;

  @JsonProperty('rating', Number, true)
  rating: number | null = null;

  @JsonProperty('title', String, true)
  title: string | null = null;

  @JsonProperty('review', String, true)
  review: string | null = null;

  @JsonProperty('patient_name', String, true)
  patientName: string | null = null;

  patientNameInit: string | null = null;
  patientNameInitAr: string | null = null;

  setPatientNameInit() {
    this.patientNameInit = this.patientName
      ? `${this.patientName.split(' ').shift()} ${this.patientName
          .split(' ')
          .pop()
          ?.charAt(0)}.`
      : null;
  }
}


@JsonObject('BookingReview')
export class BookingReview { 
  @JsonProperty('id', Number, true)
  id = undefined;

  @JsonProperty('branch', publicBranch, true)
  branch: publicBranch | null = null;
  
  @JsonProperty('doctors', [DoctorList], true)
  doctors: DoctorList[] = [];
  @JsonProperty('doctor', DoctorList, true)
  doctor: DoctorList | null = null;
  
  @JsonProperty('booking_date', String, true)
  bookingDate = undefined;
  
  @JsonProperty('services', [ReviewService], true)
  services: ReviewService[] | null = [];

  @JsonProperty('uuid', String, true)
  uuid = undefined;

  @JsonProperty('is_reviewed', Boolean, true)
  isReviewed : boolean | null = null;
}