import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from './core/core.module';
import { ContentPagesModule } from './modules/content-pages/content-pages.module';
import { SharedModule } from './modules/shared/shared.module';
import { LayoutComponent } from './layout/layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
  TransferState,
} from '@angular/platform-browser';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { Location, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { localizeBrowserLoaderFactory } from './core/utils/localize-browser.loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './core/utils/translate-browser.loader';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { FooterComponent } from './layout/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { PatientContactModalComponent } from './patient-contact-modal/patient-contact-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import localeAr from '@angular/common/locales/ar-EG';
import localeEn from '@angular/common/locales/en';
import { LoginModule } from './modules/login/login.module';
import { ExtrnalLinkLayoutComponent } from './extrnal-link-layout/extrnal-link-layout.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CanActivateProdGuard } from './core/guards/can-activate-prod.guard';

registerLocaleData(localeAr);
registerLocaleData(localeEn);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    PatientContactModalComponent,
    ExtrnalLinkLayoutComponent,
    SitemapComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserTransferStateModule,
    HttpClientModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      extend: true,
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeBrowserLoaderFactory,
        deps: [
          TranslateService,
          Location,
          LocalizeRouterSettings,
          HttpClient,
          TransferState,
        ],
      },
      initialNavigation: true,
    }),
    SharedModule,
    BrowserAnimationsModule,
    ContentPagesModule,
    CoreModule,
    FormsModule,
    NgxIntlTelInputModule,
    LoginModule,
  ],
  providers: [
    { provide: 'canActivateProdGuard', useClass: CanActivateProdGuard }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
