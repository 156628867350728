import { Injectable } from '@angular/core';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  protected _jsonConvert = new JsonConvert();

  constructor(
    protected spinner: NgxSpinnerService,
    protected errorService: ErrorService
  ) {
    this._jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }

  protected serialize<T>(obj: any) {
    try {
      return this._jsonConvert.serialize(obj);
    } catch (e) {
      this.spinner.hide();
      const error =
        'Serializing Error: ' +
        obj.constructor.name +
        '. Check stacktrace for more details.';
      this.errorService.showError(error);
      throw e;
    }
  }

  // Splitting up object deserialisation and array deserialisation
  // will lead to better static type safety.

  protected deserializeAs =
    <T>(Model: new () => any) =>
    (response: any) => {
      return response && this._jsonConvert.deserializeObject(response, Model);
    };

  protected deserializeArrayAs =
    <T>(Model: new () => any) =>
    (response: any) => {
      return response && this._jsonConvert.deserializeArray(response, Model);
    };

  protected deserializeTupleAs =
    <A, B>(ModelA: new () => A, ModelB: new () => B) =>
    (response: any) => {
      // a tuple looks like an array to the decoder, so it defaults
      // to deserializing as an array of a single type of object intead
      // of a tuple of different types of objects
      return [
        response[0].map(this.deserializeAs(ModelA)),
        response[1].map(this.deserializeAs(ModelB)),
      ] as [A, B];
    };

  protected deserializeArraySortedAs =
    <T>(Model: {
      new (): T;
      sorter: (a: T, b: T) => number;
      deserialize?: (x: any) => T;
    }) =>
    (response: any) => {
      if (Model.deserialize) {
        return response.map(Model.deserialize).sort(Model.sorter);
      } else {
        return this.deserializeArrayAs(Model)(response).sort(Model.sorter);
      }
    };

  protected serializePrimitive(
    key: any,
    obj: any,
    output: any,
    prefix = null,
    suffix = null
  ) {
    if (
      typeof obj === 'string' ||
      typeof obj === 'number' ||
      typeof obj === 'boolean'
    ) {
      if (prefix != null) {
        key = `${prefix}[${key}]`;
      }
      if (suffix != null) {
        key = `${key}[${suffix}]`;
      }
      output = output.set(key, obj);
    }
    return output;
  }

  protected serializeParams(obj: any, output: any, prefix = null) {
    // tslint:disable-next-line: forin
    for (const key in obj) {
      const val = obj[key];
      const type = typeof val;

      if (Array.isArray(val)) {
        val.forEach((f, i: any) => {
          output = this.serializePrimitive(key, f, output, prefix, i);
        });
      } else if (type === 'object') {
        continue;
      } else {
        output = this.serializePrimitive(key, val, output, prefix);
      }
    }

    return output;
  }

  protected serializeParamsObj(obj: any, output: any) {
    // tslint:disable-next-line: forin
    output = this.serialize(obj);
    return output;
  }
}
