import { ActivatedRoute } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent  {
  isBrowser;
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.activatedRoute.fragment.subscribe((frag) => {
      if (frag) {
        this.jumbTo(frag);
      }
    });
  }

  jumbTo(fragment: string) {
    if (this.isBrowser) {
      setTimeout(() => {
        this.document
          .getElementById(fragment)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1000);
    }
  }
}
